<template>
  <footer class="bg_img oh">
    <div class="newslater-wrapper" v-if="newsletterOn">
      <div class="container">
        <div class="newslater-area">
          <div class="newslater-thumb">
            <!-- <img :src="logoAlt" :title="siteName" style="max-width: 100%" /> -->
            <img
              :src="require('@/assets/images/cadastre-se.png')"
              height="200px"
            />

            <!-- <lottie-animation path="../assets/images/mail.json" /> -->
          </div>
          <div class="newslater-content">
            <div class="section-header left-style mb-low">
              <!-- <h5 class="cate">Inscreva-se</h5> -->
              <h3 class="title title-bestOffer">
                Cadastre-se gratuitamente e dê seu lance agora!
              </h3>
              <p class="best-offer">temos as melhores ofertas</p>
            </div>
            <div class="custom-buttons text-align-left w-100">
              <button type="submit" class="custom-button registerButton">
                <a href="/cadastro" style="color: #062745">Cadastre-se</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-top mt-4 py-5">
      <div class="container">
        <div class="row mb--60">
          <div class="col-sm-6 col-lg-3">
            <div class="footer-widget widget-links">
              <h5 class="title title-bestOffer">Categorias</h5>
              <ul class="links-list">
                <li v-for="(item, index) in getCategorias" :key="index">
                  <a href="#0">{{ item.descricao }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="footer-widget widget-links">
              <h5 class="title title-bestOffer">Menu</h5>
              <ul class="links-list">
                <li>
                  <router-link to="/">Home</router-link>
                </li>
                <li v-for="pagina in getPaginas" :key="pagina.titulo">
                  <a :href="'/pagina/' + pagina.rota" v-if="pagina.menu">
                    {{ pagina.titulo }}
                  </a>
                </li>
                <li>
                  <router-link to="/validar"
                    >Validação de Documentos</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="footer-widget widget-links">
              <h5 class="title title-bestOffer">Links</h5>
              <ul class="links-list">
                <li v-for="pagina in getPaginas" :key="pagina.titulo">
                  <a :href="'/pagina/' + pagina.rota" v-if="!pagina.menu">
                    {{ pagina.titulo }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="footer-widget widget-follow" v-if="paginasDinamicas">
              <h5 class="title title-bestOffer">Siga-nos</h5>
              <ul class="links-list">
                <!-- <li>
                  <a href="#0"
                    ><i class="fas fa-phone-alt"></i>(21) 2145-4575</a
                  >
                </li> -->
                <li v-if="paginasDinamicas.telefones">
                  <a :href="'mailto:' + paginasDinamicas.telefones"
                    ><i class="fas fa-phone-alt"></i
                    >{{ paginasDinamicas.telefones }}</a
                  >
                </li>
                <!-- <li>
                  <a href="#0"><i class="fab fa-whatsapp"></i>(21) 98167-5462</a>
                </li> -->

                <li v-if="paginasDinamicas.email">
                  <a :href="'mailto:' + paginasDinamicas.email"
                    ><i class="fas fa-envelope"></i
                    >{{ paginasDinamicas.email }}</a
                  >
                </li>

                <li v-if="paginasDinamicas.atendimento">
                  <a href="#0"
                    ><i class="fas fa-headset"></i
                    >{{ paginasDinamicas.atendimento }}</a
                  >
                </li>
              </ul>
              <ul
                class="social-icons"
                v-if="
                  paginasDinamicas.facebook ||
                  paginasDinamicas.instagram ||
                  paginasDinamicas.youtube
                "
              >
                <li v-if="paginasDinamicas.facebook">
                  <a :href="paginasDinamicas.facebook" title="Facebook"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <!--<li>
                  <a href="#0"><i class="fab fa-twitter"></i></a>
                </li>-->
                <li v-if="paginasDinamicas.instagram">
                  <a :href="paginasDinamicas.instagram" title="Instagram"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li v-if="paginasDinamicas.youtube">
                  <a :href="paginasDinamicas.youtube"
                    ><i class="fab fa-youtube"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="copyright-area">
          <div class="footer-bottom-wrapper">
            <div class="logo">
              <a href="/"><img :src="logo" alt="logo" :title="siteName" /></a>
            </div>
            <div>
              <img
                :src="require('@/assets/images/logo/ssl.png')"
                alt="ssl"
                title="Site Seguro - Certificado SSL"
                width="160"
              />
            </div>
            <div>
              <a
                href="https://transparencyreport.google.com/safe-browsing/search?url=eblonline.com.br"
                target="_blank"
              >
                <img
                  :src="require('@/assets/images/logo/google-site-seguro.png')"
                  alt="Google Safe Browsing"
                  title="Google Safe Browsing"
                  width="150"
                />
              </a>
            </div>
            <div class="copyright">
              <p>
                &copy; Copyright {{ ano }} | Desenvolvido por
                <b><a href="http://www.webzi.com.br">Webzi</a></b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import http from '@/service/http/http';
export default {
  data() {
    return {
      identityId: '',
      paginas: [],
      newsletterOn: true,
      logo: process.env.VUE_APP_LOGO_PATH,
      logoAlt: process.env.VUE_APP_LOGO_ALT_PATH,
      siteName: process.env.VUE_APP_SITE_NAME,
      colorPrimary: process.env.VUE_APP_PRIMARY_COLOR,
      colorSecondary: process.env.VUE_APP_SECONDARY_COLOR,
      colorConstrast: process.env.VUE_APP_CONSTRAST_COLOR,
      ano: '',
      paginasDinamicas: '',
    };
  },
  components: {
    // LottieAnimation,
  },
  computed: {
    ...mapGetters(['getCategorias', 'getPaginas']),
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    ...mapActions(['setCategorias', 'setPaginas']),
    fetchData: function () {
      if (
        this.$route.name == 'validacaodocumento' ||
        this.$route.name == 'validacaodocumentoid'
      ) {
        this.newsletterOn = false;
      } else {
        this.newsletterOn = true;
      }
    },
    getPaginasDinamicas() {
      return http({
        url: `GrupoEconomico/info`,
        method: 'get',
      }).then((resp) => {
        this.paginasDinamicas = resp.data.data;
      });
    },
  },
  created() {
    this.setPaginas();
    this.getPaginasDinamicas();
    this.ano = new Date().getFullYear();
  },
};
</script>
<style scoped>
.newslater-wrapper {
  padding-top: 50px;
}
.best-offer {
  color: #ffffff;
  font-size: 0.8rem;
}
.newslater-area {
  background-color: v-bind(colorPrimary);
}
.footer-top {
  background-color: v-bind(colorPrimary);
}

.footer-bottom {
  border-top: 1px solid v-bind(colorConstrast);
  background-color: v-bind(colorConstrast);
  color: black;
}

.copyright-area {
  border: none;
}

.footer-widget .links-list li a {
  color: v-bind(colorConstrast);
}

.footer-widget,
.footer-bottom-wrapper {
  color: #ffffffcf;
}
.title-bestOffer {
  color: v-bind(colorConstrast) !important;
}
.copyright {
  color: #000000cf;
}
.logo {
  width: 200px;
}

.custom-button:hover {
  background-color: v-bind(colorConstrast);
  color: #062745;
}
#close-button {
  position: inherit;
}
.registerButton {
  background-color: v-bind(colorConstrast);
  color: #062745;
}

.social-icons li a {
  background: v-bind(colorSecondary);
  color: v-bind(colorConstrast);
}
</style>
