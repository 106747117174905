<template>
  <div>
    <banner-info-leilao @abreModalHabilitar="abreModalHabilitar" class="mb-5" />
    <search-bar :leilaoId="leilaoId" />
    <lotes-filtrados :leilaoId="leilaoId" />
    <modal-condicoes tabInicio="condicoes" />
    <modal-verifica-identidade v-if="getLeilao && getUsuarioInfo" />
  </div>
</template>

<script>
import LotesFiltrados from './components/LotesFiltrados.vue';
import BannerInfoLeilao from './components/BannerInfoLeilao.vue';
import ModalCondicoes from '@/components/ModalCondicoes.vue';
import ModalVerificaIdentidade from '@/components/ModalVerificaIdentidade.vue';

import SearchBar from '@/components/SearchBar.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    leilaoId: String,
  },
  components: {
    LotesFiltrados,
    BannerInfoLeilao,
    ModalCondicoes,
    SearchBar,
    ModalVerificaIdentidade,
  },
  computed: {
    ...mapGetters(['getAuth', 'getLeilao', 'getUsuarioInfo']),
  },
  methods: {
    ...mapActions(['setLeilao', 'verificarAcesso', 'info']),
    async abreModalHabilitar() {
      await this.info();
      this.$store.commit('SET_LEILAO_ERRO');
      //document.getElementById("[data-target='#modalCondicoes']").click();
    },
  },
  async created() {
    await this.setLeilao(this.leilaoId);
    if (this.getAuth) {
      await this.verificarAcesso(this.leilaoId);
    }
  },
};
</script>
<style>
.featured-auction-section {
  margin-top: 88px;
}
</style>
