<template>
  <div v-if="getLoteInfo" class="box-status">
    <div v-if="!getLoteInfo.emFechamento">
      <!-- <p v-if="getLoteInfo && showDate"><b>Início do leilão: </b>{{ getLeilao.dataLeilao | formatDataHora }}</p> -->
      <h6 class="title" v-if="!getLoteInfo.emFechamento">Status:</h6>
      <span
        v-if="getLoteInfo.status.includes('Aberto')"
        class="status color-green"
        >{{ getLoteInfo.status }}</span
      >
      <span
        v-if="
          getLoteInfo.status.includes('Removido') ||
          getLoteInfo.status.includes('Fechado')
        "
        class="status color-red"
        >{{ getLoteInfo.status }}</span
      >
      <span
        v-if="getLoteInfo.status.includes('Aguardando')"
        class="status color-orange"
      >
        {{ getLoteInfo.status }}
        <i class="fa fa-info-circle" @click="showInfo"></i>
        <div class="msg" v-show="showingInfo">
          Irá iniciar após o fechamento do lote anterior
        </div>
      </span>
      <span
        v-if="getLoteInfo.status.includes('Encerrado')"
        class="status color-blue"
        >{{ getLoteInfo.status }}</span
      >
      <span v-if="getLoteInfo.status == ''" class="status color-blue"
        >Encerrado</span
      >
    </div>
    <div v-if="getLoteInfo.emFechamento">
      <div v-if="isFinishCountdown">
        <h6 class="title">Status:</h6>
        <span class="status color-yellow">Homologando...</span>
      </div>
      <div v-if="!isFinishCountdown">
        <h6 class="title">Fecha em:</h6>
        <count-down
          :final="getLoteInfo.dataEncerramento"
          :atual="getLoteInfo.dataAtualUtc"
          @FinishCountdown="setFinishCountdown"
          @LastMinute="setLastMinute"
          @LastThirtySec="setLastThirtySec"
          @LastTenSec="setLastTenSec"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import CountDown from "@/components/CountDown.vue";

moment.locale("pt-br");
export default {
  components: {
    CountDown,
  },
  data() {
    return {
      isFinishCountdown: false,
      showingInfo: false,
      timer: null,
    };
  },
  computed: {
    ...mapGetters([
      "getLoteInfo",
      "getLoteInfoLoading",
      "getLeilao",
      "getLote",
    ]),
    showDate() {
      return (
        (this.getLeilao.statusSite === "aberto" ||
          this.getLeilao.statusSite === "breve") &&
        moment.utc(this.getLeilao.dataLeilao).isAfter(moment.utc())
      );
    },
  },
  methods: {
    setFinishCountdown(isFinish) {
      this.isFinishCountdown = isFinish;
      this.$emit("FinishCountdown", isFinish);
    },
    setLastMinute() {
      this.$emit("LastMinute");
    },
    setLastThirtySec() {
      this.$emit("LastThirtySec");
    },
    setLastTenSec() {
      this.$emit("LastTenSec");
    },
    showInfo() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.showingInfo = !this.showingInfo;

      if (this.showingInfo) {
        this.timer = setTimeout(() => {
          this.showingInfo = false;
        }, 2000);
      }
    },
    onEventNewLance(loteinfo) {
      if (this.getLote.loteId === loteinfo.loteId) {
        this.isFinishCountdown = false;
      }
    },
  },
  created() {
    this.$signalR.$on("new-lance", this.onEventNewLance);
  },
  filters: {
    formatDataHora(value) {
      return moment.utc(value).local().format("DD/MM/YYYY [às] HH:mm");
    },
  },
};
</script>

<style scoped>
.msg {
  max-width: 200px;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 2px 0px black;
  position: absolute;
  z-index: 99999;
  right: 3px;
  color: #625d5d;
  background: #fff;
  font-size: 10px;
  opacity: 1;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.msg i {
  color: #fff;
  border: 1px solid rgb(183, 182, 192);
}

.small-status {
  font-size: 13px !important;
}

i {
  font-size: 18px;
  padding-left: 5px;
  color: rgb(183, 182, 192);
  cursor: pointer;
}

.box-status {
  padding-bottom: 10px;
}

.countdown {
  padding: 0 !important;
  border: none !important;
}

.status {
  font-size: 30px;
  font-weight: bold;
}

.color-green {
  color: #138111;
}

.color-red {
  color: #e0280f;
}

.color-orange {
  color: #de8430;
  font-size: 20px;
}

.color-yellow {
  color: #f9c718;
}

.color-blue {
  color: #007ec3;
}
</style>
