<template>
  <div
    class="modal fade"
    id="modalVerifica"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalVerificasTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content" v-if="tela == 'verifica'">
        <div class="modal-body" style="height: 360px">
          <div class="row text-center pb-3">
            <div class="col-12 pt-10">
              <img
                :src="require('@/assets/images/shield-64.png')"
                class="pb-10"
              />
              <p class="pt-10">
                <strong>Verificação de identidade</strong>
              </p>
              <p>
                <small
                  >Enviaremos um código de validação via {{ channel }}</small
                >
              </p>
            </div>
          </div>

          <div class="row text-center">
            <div class="col-12">
              <form
                class="login-form"
                style="display: flex; justify-content: center"
                @submit.prevent=""
              >
                <div
                  class="form-group"
                  style="width: 300px"
                  v-if="channel == 'SMS'"
                >
                  <label for="para"><i class="fas fa-mobile"></i></label>
                  <input
                    type="text"
                    id="para"
                    placeholder="Telefone"
                    v-model="para"
                    required
                  />
                </div>

                <div
                  class="form-group"
                  v-if="channel == 'EMAIL'"
                  style="width: 300px"
                >
                  <label for="para"><i class="far fa-envelope"></i></label>
                  <input
                    type="text"
                    id="para"
                    placeholder="E-mail"
                    v-model="para"
                    required
                  />
                </div>
              </form>
              <p class="text-danger pb-10" v-show="getLeilaoErro">
                <small>{{ getLeilaoErro.mensagem }}</small>
              </p>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-12">
              <small
                ><a
                  href="javascript:void(0);"
                  @click="mudarTipoHabilitacao()"
                  style="text-decoration: underline"
                >
                  Realizar validação por
                  {{ channel === 'SMS' ? 'EMAIL' : 'SMS' }}
                </a></small
              >
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a
            v-if="getAuth"
            href="#0"
            @click="solicitarAcesso"
            class="custom-button"
          >
            <i
              class="fa fa-spin fa-spinner"
              v-if="getLeiloesVerificacaoLoading"
            ></i>
            ENVIAR
          </a>
        </div>
      </div>

      <!-- validação de acesso -->
      <div class="modal-content" v-else-if="tela == 'valida'">
        <button
          v-show="false"
          id="fechaModalCodigo"
          data-dismiss="modal"
        ></button>
        <div class="modal-body" style="height: 330px">
          <div class="row text-center pb-3">
            <div class="col-12 pt-10">
              <img
                :src="require('@/assets/images/shield-64.png')"
                class="pb-10"
              />
              <p class="pt-10">
                <strong>Verificação de identidade</strong>
              </p>
              <p>
                <small>Insira o códido recebido por {{ channel }}</small>
              </p>
            </div>
          </div>

          <div class="row text-center">
            <div class="col-12">
              <form
                class="login-form"
                style="display: flex; justify-content: center"
                @submit.prevent=""
              >
                <div class="form-group" style="width: 220px">
                  <label for="codigo"><i class="fa fa-hashtag"></i> </label>
                  <input
                    type="text"
                    id="codigo"
                    placeholder="codigo"
                    v-model="codigo"
                    required
                  />
                </div>
              </form>
              <p class="text-danger" v-show="getLeilaoErro">
                <small>{{ getLeilaoErro.mensagem }}</small>
              </p>
              <p class="text-success" v-show="msgReenvioCodigo">
                <small>{{ msgReenvioCodigo }}</small>
              </p>
            </div>
            <div class="col-12 pt-4" v-if="!getReenviaCodigoLoading">
              <a
                href="#0"
                class="pr-2"
                @click="reeviarCodigo"
                v-if="!reenvioCodigoBtnDisabled"
              >
                Reenviar código</a
              >
              <a class="pr-2" v-else> Reenviar código</a>
            </div>
            <div class="col-12 pt-4" v-else>
              <a href="#0" class="pr-2"> Reenviando</a>
              <i class="fa fa-spin fa-spinner"></i>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a
            v-if="getAuth"
            href="#0"
            @click="validarAcesso"
            class="custom-button"
          >
            <i
              class="fa fa-spin fa-spinner"
              v-if="getLeiloesVerificacaoLoading"
            ></i>
            ENVIAR
          </a>
        </div>
      </div>

      <!-- validação de acesso doc -->
      <div class="modal-content" v-else-if="tela == 'documentos'">
        <button v-show="false" id="fechaModal" data-dismiss="modal"></button>
        <div class="modal-body" style="height: 470px">
          <div class="row text-center pb-3">
            <div class="col-12 pt-10">
              <img
                :src="require('@/assets/images/shield-64.png')"
                class="pb-10"
              />
              <p class="pt-10">
                <strong>Verificação de identidade</strong>
              </p>
              <p>
                <small>Faça upload dos documentos solicitados </small>
              </p>
              <p class="alert alert-danger p-0" v-if="msgErrorUpload">
                <small v-html="msgErrorUpload"></small>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <form class="login-form custom-form" @submit.prevent="">
                <div
                  v-for="(item, index) in fitraRegraPorPessoa.tipoDocumentos"
                  :key="index"
                  class="tipoDocumentos"
                >
                  <label
                    :for="item.tipoDocumento.codigo"
                    class="custom-file-upload"
                    :class="{
                      'com-file': nomeArquivo(item.tipoDocumento.codigo),
                    }"
                  >
                    <span
                      :class="{
                        'label-com-file': nomeArquivo(
                          item.tipoDocumento.codigo
                        ),
                      }"
                      >{{ item.tipoDocumento.codigo }}
                    </span>
                    <span
                      v-if="nomeArquivo(item.tipoDocumento.codigo)"
                      class="file-name"
                    >
                      {{ nomeArquivo(item.tipoDocumento.codigo).name }}</span
                    >
                    <!-- <span v-else></span> -->
                  </label>
                  <input
                    type="file"
                    :id="item.tipoDocumento.codigo"
                    v-on:change="setArquivo($event, item)"
                    accept="image/*, application/pdf"
                  />
                </div>
              </form>
              <p class="text-danger" v-show="getLoteVerificacaoErro">
                <small>{{ getLeilaoErro.mensagem }}</small>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a
            v-if="getAuth"
            href="#0"
            @click="solicitaAcessoPorDocumento"
            class="custom-button"
          >
            <i
              class="fa fa-spin fa-spinner"
              v-if="getLoteVerificacaoLoading"
            ></i>
            ENVIAR
          </a>
        </div>
      </div>

      <!-- validação de acesso doc -->
      <div class="modal-content" v-else-if="tela == 'aguardaAnalise'">
        <button v-show="false" id="fechaModal" data-dismiss="modal"></button>
        <div class="modal-body" style="height: 330px">
          <div class="row text-center pb-3">
            <div class="col-12 pt-10">
              <img
                :src="require('@/assets/images/shield-64.png')"
                class="pb-10"
              />
              <p class="pt-10">
                <strong>Verificação de identidade</strong>
              </p>
              <p>
                <small
                  >Estamos analisando os documentos e em breve você receberá um
                  email com a confirmação.</small
                >
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a href="#0" class="custom-button" data-dismiss="modal"> FECHAR </a>
        </div>
      </div>

      <div class="modal-content" v-else-if="tela == 'semregra'">
        <button v-show="false" id="fechaModal" data-dismiss="modal"></button>
        <div class="modal-body" style="height: 330px">
          <div class="row text-center pb-3">
            <div class="col-12">
              <img
                :src="require('@/assets/images/alert-64.png')"
                class="pb-5"
              />
              <p class="alert alert-danger">
                <small>{{ msgSemRegra }}</small>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a href="#0" class="custom-button" data-dismiss="modal"> FECHAR </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      para: '',
      mensagem: '',
      tela: '',
      codigo: '',
      arquivo: '',
      msgReenvioCodigo: '',
      reenvioCodigoBtnDisabled: false,
      arquivos: [],
      obrigatorioDocFotoStyle: '',
      msgErrorUpload: null,
      msgSemRegra: '',
      channel: 'SMS',
      defaultRegraHabilitacaoId: 1,
    };
  },
  watch: {
    arquivos: {
      handler: function (val) {
        if (val.some((e) => e.alternativo)) {
          this.obrigatorioDocFotoStyle = '';
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'getAuth',
      'getUsuarioInfo',
      'getLeilao',
      'getLeiloesVerificacaoLoading',
      'getLeilaoErro',
      'getSolicitacaoAcesso',
      'getReenviaCodigoLoading',
      'getLote',
      'getLeilaoHabilitado',
      'getLoteVerificacaoLoading',
      'getLoteVerificacaoErro',
    ]),
    fitraRegraPorPessoa() {
      return this.getLeilao.regrasHabilitacao.find(
        (e) => e.tipoPessoa == this.getUsuarioInfo.tipoPessoa
      );
    },

    regraDocAlternativo() {
      return this.arquivos.some((e) => e.alternativo);
    },
  },
  methods: {
    ...mapActions([
      'solicitaAcesso',
      'validaAcesso',
      'verificarAcesso',
      'reenviaCodigo',
      'solicitaAcessoLote',
      'info',
    ]),
    nomeArquivo(cod) {
      if (this.arquivos.length == 0) return;

      return this.arquivos.find((e) => e.codigo == cod);
    },
    async setArquivo(e, item) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.msgErrorUpload = null;
      // console.log(item.alternativo);
      let base64 = await this.toBase64(files[0]);
      base64 = base64.split(',')[1];

      const existe = this.arquivos.findIndex(
        (e) => e.codigo == item.tipoDocumento.codigo
      );
      if (existe != -1) {
        //console.log(existe);
        this.arquivos.splice(existe, 1);
      }

      this.arquivos.push({
        codigo: item.tipoDocumento.codigo,
        name: files[0].name,
        alternativo: item.alternativo,
        arquivoBase64: base64,
        tipoDocumentoId: item.tipoDocumentoId,
        tipoArquivo: files[0].type,
      });
    },
    async solicitarAcesso() {
      if (!this.para) return;
      const payload = {
        leilaoId: this.getLeilao.id,
        regraHabilitacaoId: this.defaultRegraHabilitacaoId,
        para: this.para,
      };
      await this.solicitaAcesso(payload);

      if (!this.getLeilaoErro) {
        this.msgReenvioCodigo = '';
        this.tela = 'valida';
      }
    },

    async validarAcesso() {
      if (!this.codigo) return;
      const payload = {
        leilaoId: this.getLeilao.id,
        solicitacaoId: this.getSolicitacaoAcesso.solicitacaoId,
        codigo: this.codigo,
      };
      await this.validaAcesso(payload);

      if (!this.getLeilaoErro) {
        await this.verificarAcesso(this.getLeilao.id);
        document.querySelector('#fechaModalCodigo').click();
      }
    },
    async reeviarCodigo() {
      this.msgReenvioCodigo = '';
      if (this.reenvioCodigoBtnDisabled) return;

      const payload = {
        to:
          this.channel == 'SMS'
            ? '+55' + this.para.replace(/\D/g, '')
            : this.para,
        channel: this.channel.toLowerCase(),
      };

      await this.reenviaCodigo(payload);
      this.codigo = '';
      if (!this.getLeilaoErro) {
        this.msgReenvioCodigo = 'Código enviado';
        this.reenvioCodigoBtnDisabled = true;
      }
      setTimeout(() => {
        this.reenvioCodigoBtnDisabled = false;
        this.msgReenvioCodigo = '';
      }, 10000);
    },
    async solicitaAcessoPorDocumento() {
      const obrigatorio = this.fitraRegraPorPessoa.tipoDocumentos
        .filter((e) => !e.alternativo)
        .map((f) => f.tipoDocumento.codigo);

      const alternativo = this.fitraRegraPorPessoa.tipoDocumentos.filter(
        (e) => e.alternativo
      );

      const arquivosObrigatorios = this.arquivos
        .filter((e) => !e.alternativo)
        .map((f) => f.codigo);

      const all = obrigatorio.every((a) => arquivosObrigatorios.includes(a));
      if (!all) {
        const campos = obrigatorio.toString().replaceAll(',', ' , ');
        this.obrigatorioDocFotoStyle = 'border: dashed 1px red';
        this.msgErrorUpload = `Documentos obrigatórios: ${campos}`;

        return;
      }

      if (alternativo.length > 0 && !this.regraDocAlternativo) {
        let alternativosStr = alternativo.map((e) => e.tipoDocumento.codigo);
        alternativosStr = alternativosStr.toString().replaceAll(',', ' ou ');
        if (this.getUsuarioInfo.tipoPessoa == 'PF') {
          this.msgErrorUpload =
            'É obrigatório pelo menos um documento com foto';
        }
        if (this.getUsuarioInfo.tipoPessoa != 'PF') {
          this.msgErrorUpload = `É obrigatório pelo menos um dos documentos: ${alternativosStr}`;
        }
        return;
      }

      if (this.arquivos.length == 0) return;

      const documentos = this.arquivos.map((e) => {
        return {
          tipoDocumentoId: e.tipoDocumentoId,
          arquivoBase64: e.arquivoBase64,
          tipoArquivo: e.tipoArquivo,
        };
      });
      const payload = {
        aceiteTermo: true,
        leilaoId: this.getLeilao.id,
        tipoLoteId: this.getLote ? this.getLote.tipoLoteId : null,
        regraHabilitacaoId: this.fitraRegraPorPessoa.regraHabilitacaoId,
        documentos,
      };
      await this.solicitaAcessoLote(payload);

      if (!this.getLoteVerificacaoErro) {
        this.tela = 'aguardaAnalise';
        await this.verificarAcesso(this.getLeilao.id);
      } else {
        this.msgErrorUpload =
          this.getLoteVerificacaoErro.Message +
            '<br/>' +
            this.getLoteVerificacaoErro.Errors.Exception ||
          'Erro não identificado, por favor tente novamente!';
      }
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async mudarTipoHabilitacao() {
      this.channel = this.channel === 'SMS' ? 'EMAIL' : 'SMS';
      if (this.getUsuarioInfo && this.channel === 'SMS') {
        this.para = this.getUsuarioInfo.telefone;
        this.defaultRegraHabilitacaoId = 1;
      } else {
        this.para = this.getUsuarioInfo.email;
        this.defaultRegraHabilitacaoId = 2;
      }
    },
  },
  async mounted() {
    await this.info();
    await this.verificarAcesso(this.getLeilao.id);
    const regrasHabilitacao = this.getLeilao.regrasHabilitacao;
    let regra = regrasHabilitacao.find(
      (e) => e.tipoValidacaoRegra == 'EMAIL' || e.tipoValidacaoRegra == 'TEL'
    );

    if (!regra) {
      regra = regrasHabilitacao.find(
        (e) => e.tipoPessoa === this.getUsuarioInfo.tipoPessoa
      );

      if (!regra) {
        this.msgSemRegra =
          'Somente ' +
          (this.getUsuarioInfo.tipoPessoa === 'PF'
            ? 'Pessoas Jurídicas'
            : 'Pessoas Físicas') +
          ' poderão participar deste leilão ';
        this.tela = 'semregra';
        return;
      }
    }

    if (regra.tipoValidacaoRegra == 'EMAIL') {
      this.channel = 'EMAIL';
      this.defaultRegraHabilitacaoId = 2;
    }

    this.para =
      this.getUsuarioInfo && this.channel === 'SMS'
        ? this.getUsuarioInfo.telefone
        : this.getUsuarioInfo.email;

    this.tela =
      regra.tipoValidacaoRegra.includes('DOC') &&
      !this.getLeilaoHabilitado.docValido
        ? 'documentos'
        : 'verifica';
  },
};
</script>

<style scoped>
.login-form .form-group input,
select {
  padding-left: 60px;
  padding-right: 0;
}
.custom-file-upload {
  text-align: left;
  border: 2px dashed #ccc;
  width: 100%;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.custom-file-upload span {
  display: block;
}

.file-name {
  font-size: 13px;
  font-weight: 600;
  color: #268e0c;
}

.com-file {
  border: 1px solid #268e0c;
}

.label-com-file {
  font-size: 11px;
}

input[type='file'] {
  display: none;
}
.custom-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.tipoDocumentos {
  display: flex;
  flex-direction: column;
}

.alert-danger {
  margin: 10px;
}
</style>
